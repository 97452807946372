export const deleteCustomRowFromStructure = (state: TODO, payload: TODO) => {
  const updatedState = { ...state };
  const { rows } = payload;

  updatedState.theData.find((question: TODO) => question.id === payload.id).groups = rows.map((newRow: TODO) => {
    return {
      id: newRow.id,
      active: true,
      selected: false,
      code: newRow.code,
      isCustom: newRow.isCustom,
      customExpr: newRow.customExpr,
      text: newRow.texts[state.defaultLanguage],
    }
  })

  return updatedState;
}