export const deleteCustomRowsFromColumn = (state: TODO, payload: TODO) => {
  const updatedState = { ...state };
  const { data, defaultLanguage, questionInfo } = payload;

  updatedState.state[questionInfo.source].find((el: { id: string }) => el.id === questionInfo.id).groups = data.rows.map((newRow: TODO) => {
    return {
      id: newRow.id,
      active: true,
      selected: false,
      code: newRow.code,
      isCustom: newRow.isCustom,
      customExpr: newRow.customExpr,
      text: newRow.texts[defaultLanguage],
    }
  })

  updatedState.state.updateTable = true;

  return updatedState;
}