export const addCustomRowToStructure = (state: TODO, payload: TODO) => {
  const updatedState = { ...state };
  const newRow = payload.rows[payload.rows.length - 1];

  updatedState.theData.find((question: TODO) => question.id === payload.id).groups.push({
    id: newRow.id,
    active: true,
    selected: false,
    code: newRow.code,
    isCustom: newRow.isCustom,
    customExpr: newRow.customExpr,
    text: newRow.texts[state.defaultLanguage],
  });

  return updatedState;
}