export const mapRoundDirection = (number?: number) => {
  switch (number) {
    case 0:
      return 'UP';
    case 1:
      return 'DOWN';
    default:
      return 'UP';
  }
};
