import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import type { RootState } from "../../../../../../../../store/reducers/rootReducer";
import { fetchDeleteWithBody } from "../../../../../../../../services/services";

type Props = {
  defaultLanguage: string
  checkedQuestions: TODO[]
  analysisType: string
  selectedQuestion: {
    id: string
    title: string
    groups: {
      code: number
    }[]
  }
  selectedCustomRows: {
    id: string
    code: number
    text: string
    customExpr: string
  }[]
  handleClose: () => void
  closeQuestionEditor: () => void
}

export const QuestionEditorDeleteCustomRowModal = ({ analysisType, defaultLanguage, checkedQuestions, selectedQuestion, selectedCustomRows, handleClose, closeQuestionEditor }: Props) => {
  const dispatch = useDispatch();
  const { name, dataset }: { name: string, dataset: string } = useParams();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [isDeleting, setIsDeleting] = useState(false);

  const selectedQuestionSource = checkedQuestions.find((question: TODO) => question.id === selectedQuestion.id);

  const onDeleteCustomRowHandler = () => {
    setIsDeleting(true);
    const body = { questionId: selectedQuestion.title, rowIds: selectedCustomRows.map(el => el.id) };
    fetchDeleteWithBody(`an/projects/${name}/analysis/${dataset}/custom-rows`, token, body)
      .then((res: TODO) => res.json())
      .then((data: TODO) => {
        setIsDeleting(false);
        if (data.error || data.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error || data.message } })
        } else {
          dispatch({ type: 'DELETE_CUSTOM_ROW_FROM_STRUCTURE', payload: data });
          if (analysisType === 'crosstab') {
            dispatch({ type: 'XT_DELETE_CUSTOM_ROWS_FROM_COLUMN', payload: { data: data, questionInfo: selectedQuestionSource, defaultLanguage: defaultLanguage } });
          }
          closeQuestionEditor();
          handleClose();
        }
      })
  }

  return (
    <Dialog
      width={550}
      className="question-editor"
      onClose={handleClose}
      title={"Delete custom row"}>
      <div className="p-3">
        <p className="m-0">Are you sure you want to delete the selected custom row?</p>
      </div>
      <DialogActionsBar>
        <button type='button' className="k-button btn-secondary" onClick={handleClose}>
          Cancel
        </button>
        <button
          type='button'
          disabled={isDeleting}
          className="k-button btn-danger"
          onClick={onDeleteCustomRowHandler}>
          {
            isDeleting &&
            <div className="spinner-border spinner-border-sm">
              <span className="sr-only">Loading...</span>
            </div>
          }
          Delete
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}