import { useEffect } from "react"
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../store/reducers/rootReducer";
import { fetchGetJson as getAnalysisStructure } from "../../../services/services";

export const useFetchAnalysisStructure = (didMount: boolean, setDidMount: (val: boolean) => void, setIsLoading: (val: boolean) => void, setDidMountSession: (val: boolean) => void, setCreateQuestionData: (val: TODO) => void) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const params: { name: string, dataset: string, analysisType: string } = useParams()
  const { selectedSession } = useSelector((theState: RootState) => ({ selectedSession: theState.breadcrumbStateReducer.selectedSession }), shallowEqual);

  const isSurveyAnalysis = params.analysisType === "surveys" ? `an/projects/${params.name}/analysis/surveys/${params.dataset}/structure` : `an/projects/${params.name}/analysis/${params.dataset}/structure`

  useEffect(() => {
    if (selectedSession.name === "") {
      dispatch({ type: 'UPDATE_SELECTED_SESSION', payload: { name: '', id: '', lastSaved: '' } })
    }

    if (didMount) {
      setDidMount(false);
      const newData: TODO = []
      if (params.dataset) {
        getAnalysisStructure(isSurveyAnalysis, token)
          .then((res: TODO) => {
            setDidMountSession(res.hasWorkingSession)
            if (res && (res.message || res.error)) {
              dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } })
            } else if (res?.questions) {
              dispatch({ type: "SET_STRUCTURE", payload: res })
              dispatch({ type: "SET_EDITING_LANGUAGE", payload: res.defaultLanguage })
              dispatch({ type: "SET_DEFAULT_LANGUAGE", payload: res.defaultLanguage })
              dispatch({ type: "SET_LANGUAGES", payload: res.languages })
              setCreateQuestionData(res)
              res.questions.forEach((ques: TODO, index: number) => {
                newData.push({
                  selected: false,
                  id: ques.id ? ques.id : index + 1,
                  title: ques.qno.slice(0, -2),
                  content: ques.texts[res.defaultLanguage],
                  subqIndex: ques.subqIndex,
                  groups: ques.rows.map((item: TODO) => {
                    return {
                      id: item.id,
                      selected: false,
                      active: true,
                      code: (ques.type !== 'n' && ques.type !== 'm') ? item.position : item.code,
                      text: item.texts[res.defaultLanguage],
                      customExpr: item.customExpr,
                      isCustom: item.isCustom
                    }
                  }),
                  subGroups: ques.columns ? ques.columns.map((subGroup: TODO) => {
                    return {
                      id: subGroup.id,
                      code: subGroup.code,
                      text: subGroup.texts[res.defaultLanguage],
                      selected: false,
                      active: true
                    }
                  }) : [],
                  type: ques.type,
                  isCustom: ques.isCustom
                })
              })
              if (params.analysisType === "surveys") {
                newData.forEach((item: TODO) => {
                  if (newData.filter((el: TODO) => el.title === item.title).length > 1) {
                    item.extendedTitle = res.questions.find((question: TODO) => question.id === item.id).qno
                  }
                })
              }
              dispatch({ type: 'UPDATE_OLD_DATA', payload: newData })
              setIsLoading(false)
              dispatch({ type: 'SET_INITIAL_DATA', payload: newData })
            }
          })
      }
    }
  }, [didMount, params, dispatch, token, selectedSession.name,])
}