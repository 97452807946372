import { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import {
  DropDownList,
  type DropDownListChangeEvent,
  type DropDownListFilterChangeEvent,
} from '@progress/kendo-react-dropdowns';
import {
  type CompositeFilterDescriptor,
  filterBy,
} from '@progress/kendo-data-query';
import AdvancedWeightingTable from './AdvancedWeightingTable';
import {
  fetchGetJson,
  fetchPostJson,
} from '../../../../../../../../services/services';
import { useDispatch } from 'react-redux';
import {
  AdvancedWeightingTableRef,
  Category,
  Row,
  TemplateData,
  Variable,
} from './types/types';

interface StepTwoProps {
  token: string;
  projectId: string;
  datasetId: string;
  mainTemplateData: TemplateData | null;
  setMainTemplateData: (value: TemplateData) => void;
  validateTemplateData: (
    template: TemplateData
  ) => Promise<{ validate: boolean }>;
  isValidating: boolean;
  setShouldValidate: (value: boolean) => void;
  tableRef: React.RefObject<AdvancedWeightingTableRef>;
}

const transformVariableId = (variableId: string) => variableId.split('.')[0];

const getSubgroupFromExpression = (expression: string) =>
  expression.split('=')[0].replace(/\\/g, '');

const findSubgroupFromVariables = (
  variables: Variable[],
  categories?: Category[]
) => {
  if (!categories) return [];
  const subGroupName = getSubgroupFromExpression(categories[0].expression);

  const currentSubGroupVariable = variables.filter(
    variable => transformVariableId(variable.qno) === subGroupName
  );

  const rows = [];

  for (const category of categories) {
    const categoryRowCode = category.expression.split('=')[1];
    const mappedRow = currentSubGroupVariable[0].rows.find(
      row => row.code.toString() === categoryRowCode.toString()
    );

    const rowItem = {
      ...(mappedRow?.code
        ? mappedRow
        : ({
            code: categoryRowCode,
            texts: { '': category.text },
            id: '',
          } as unknown as Row)),
    };
    rows.push(rowItem);
  }

  const formattedSubGroupVariable = {
    ...currentSubGroupVariable[0],
    rows,
  };

  return [formattedSubGroupVariable];
};

const findSubgroupFromInitialVariables = (
  variables: Variable[],
  subGroup: Variable[]
) => variables.find(variable => variable.qno === subGroup[0].qno);

const getVariableSubgroupLength = (
  variables: Variable[],
  subGroup: Variable[]
) => {
  if (!subGroup || !subGroup.length) return 0;
  const subGroupVariable = findSubgroupFromInitialVariables(
    variables,
    subGroup
  );
  return subGroupVariable?.rows.length || 0;
};

const StepTwo = ({
  token,
  projectId,
  datasetId,
  mainTemplateData,
  setMainTemplateData,
  validateTemplateData,
  isValidating,
  setShouldValidate,
  tableRef,
}: StepTwoProps) => {
  const dispatch = useDispatch();
  const [variables, setVariables] = useState<Variable[]>([]);
  const [filteredSubgroupData, setFilteredSubgroupData] = useState<Variable[]>(
    []
  );
  const [filteredTargetData, setFilteredTargetData] = useState<Variable[]>([]);
  const [subGroup, setSubGroup] = useState<Variable[]>([]);
  const [targets, setTargets] = useState<Variable[]>([]);
  const [templateData, setTemplateData] = useState<TemplateData | null>(null);

  const [selectedSubgroups, setSelectedSubgroups] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedTargets, setSelectedTargets] = useState<{
    [key: string]: { [expression: string]: boolean };
  }>({});

  const hasDisabledSubgroups =
    subGroup[0]?.rows.length < getVariableSubgroupLength(variables, subGroup);
  const hasMergedSubgroups = subGroup[0]?.rows.some(row =>
    row.code.toString().split('').includes(';')
  );

  useEffect(() => {
    const fetchVariables = async () => {
      try {
        const response: Variable[] = await fetchGetJson(
          `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced/candidates`,
          token
        );
        setVariables(response);
      } catch (error) {
        console.error('Error fetching variables:', error);
      }
    };
    fetchVariables();
  }, [projectId, datasetId, token]);

  useEffect(() => {
    if (variables.length > 0 && mainTemplateData) {
      setTemplateData(mainTemplateData);

      const newSubGroup: Variable[] = [];
      const newTargets: Variable[] = [];
      if (
        mainTemplateData.subGroup &&
        Array.isArray(mainTemplateData.subGroup.categories)
      ) {
        const subGroupVariable = findSubgroupFromVariables(
          variables,
          mainTemplateData.subGroup.categories
        );
        newSubGroup.push(subGroupVariable?.[0]);
      }

      if (mainTemplateData.targets && Array.isArray(mainTemplateData.targets)) {
        for (const target of mainTemplateData.targets) {
          const targetId = target.id;
          const findTargetInVariables = variables.find(
            variable => transformVariableId(variable.qno) === targetId
          );
          newTargets.push({
            ...findTargetInVariables,
            rows: target.targetCells.map(cell => ({
              code: cell.expression.split('=')[1],
              texts: { '': cell.text },
              id: '',
            })),
          } as unknown as Variable);
        }
      }

      setSubGroup(newSubGroup);
      setTargets(newTargets);
    } else if (!mainTemplateData) {
      setTemplateData(null);
      setSubGroup([]);
      setTargets([]);
    }
  }, [mainTemplateData, variables]);

  useEffect(() => {
    const getAvailableVariables = (
      allVariables: Variable[],
      selectedVariables: Variable[]
    ) => {
      const selectedIds = selectedVariables.map(v => v.id);
      return allVariables.filter(v => !selectedIds.includes(v.id));
    };

    setFilteredSubgroupData(getAvailableVariables(variables, subGroup));
    setFilteredTargetData(getAvailableVariables(variables, targets));
  }, [variables, subGroup, targets]);

  const filterData = (
    data: Variable[],
    filter: CompositeFilterDescriptor
  ): Variable[] => {
    return filterBy(data, filter);
  };

  const handleSubgroupFilterChange = (event: DropDownListFilterChangeEvent) => {
    const filter = event.filter;
    const newData =
      filter?.value && filter.value.length > 0
        ? filterData(variables, { logic: 'and', filters: [filter] })
        : variables.slice();
    setFilteredSubgroupData(newData);
  };

  const handleTargetFilterChange = (event: DropDownListFilterChangeEvent) => {
    const filter = event.filter;
    const newData =
      filter?.value && filter.value.length > 0
        ? filterData(variables, { logic: 'and', filters: [filter] })
        : variables.slice();
    setFilteredTargetData(newData);
  };

  const createWeightTemplate = async (
    subgroupVariable: Variable[],
    targetsVariable: Variable[],
    transformedSubgroupRows?: Variable[],
    transformedTargetsRows?: Row[],
    targetId?: string
  ) => {
    try {
      setShouldValidate(true);
      const subGroupQuestions = subgroupVariable.map(variable => ({
        id: transformVariableId(variable.qno),
        subqIndex: variable.subqIndex,
        rows: transformedSubgroupRows
          ? [...transformedSubgroupRows[0].rows]
          : variable.rows.map(row => ({ code: row.code, isDisabled: false })),
        isDisabled: false,
      }));
      const targetsQuestions = targetsVariable.map(variable => ({
        id: transformVariableId(variable.qno),
        subqIndex: variable.subqIndex,
        rows:
          transformedTargetsRows &&
          targetId === transformVariableId(variable.qno)
            ? transformedTargetsRows
            : variable.rows.map(row => ({ code: row.code })),
        isDisabled: false,
      }));

      const payload = {
        subGroup:
          subgroupVariable.length > 0
            ? {
                isInterlocked: false,
                questions: subGroupQuestions,
              }
            : null,
        targets: {
          questions: targetsQuestions,
        },
      };

      const response: TemplateData = await fetchPostJson(
        `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced/template-definition`,
        token,
        payload
      );
      setTemplateData({ ...response });
      setMainTemplateData({ ...response });
      setSelectedSubgroups({});
      setSelectedTargets({});

      console.log('Response from create', response);

      // if (mainTemplateData && (transformedTargetsRows || transformedSubgroupRows)) {
      //   await validateTemplateData(mainTemplateData);
      //   setShouldValidate(false);
      // }

      return response;
    } catch (error) {
      console.error('Error creating weight template:', error);
    }
  };

  const handleSubgroupChange = (event: DropDownListChangeEvent) => {
    const variable = event.target.value as Variable;
    if (variable) {
      setSubGroup(() => {
        const newSubGroup = [variable];
        createWeightTemplate(newSubGroup, targets);
        return newSubGroup;
      });
    }
  };

  const handleTargetChange = (event: DropDownListChangeEvent) => {
    const variable = event.target.value as Variable;
    if (variable) {
      setTargets(prevTargets => {
        const alreadyExists = prevTargets.some(
          target => target.id === variable.id
        );
        if (alreadyExists) {
          dispatch({
            type: 'SHOW_WARNING_NOTIFICATION',
            payload: { msg: 'Target already added' },
          });
          return prevTargets;
        }

        const newTargets = [...prevTargets, variable];
        createWeightTemplate(subGroup, newTargets);
        return newTargets;
      });
    }
  };

  const removeSubGroup = () => {
    setSubGroup([]);
    createWeightTemplate([], targets);
  };

  const removeTarget = (targetId: string) => {
    setTargets(prevTargets => {
      const updatedTargets = prevTargets.filter(
        target => transformVariableId(target.qno) !== targetId
      );
      createWeightTemplate(subGroup, updatedTargets);
      return updatedTargets;
    });
  };

  const handleEnableAllSubgroups = async () => {
    if (!hasDisabledSubgroups) {
      dispatch({
        type: 'SHOW_WARNING_NOTIFICATION',
        payload: { msg: 'No disabled subgroups' },
      });
      return;
    }

    const subGroupFromVariables: Variable | undefined =
      findSubgroupFromInitialVariables(variables, subGroup);

    if (subGroupFromVariables) {
      setSubGroup(() => {
        const newSubGroup = [subGroupFromVariables];
        createWeightTemplate(newSubGroup, targets);
        return newSubGroup;
      });
    }
  };

  const findSubgroupRowIndex = (subgroupRow: Row[], text: string) => {
    return subgroupRow.findIndex(
      row => Object.values(row.texts).join('') === text
    );
  };

  const handleDisableSubgroups = async () => {
    const selectedSubgroupNames = Object.keys(selectedSubgroups).filter(
      key => selectedSubgroups[key]
    );

    if (selectedSubgroupNames.length === 0) {
      dispatch({
        type: 'SHOW_WARNING_NOTIFICATION',
        payload: { msg: 'No subgroups selected' },
      });
      return;
    }

    if (selectedSubgroupNames.length >= subGroup[0].rows.length) {
      dispatch({
        type: 'SHOW_WARNING_NOTIFICATION',
        payload: { msg: 'Cannot disable all subgroups' },
      });
      return;
    }

    const selectedSubgroupRowIndexArray = selectedSubgroupNames.map(name =>
      findSubgroupRowIndex(subGroup[0].rows, name)
    );

    const subGroupRowPayload = {
      rows: subGroup[0].rows.map((row, index) => ({
        code: row.code,
        isDisabled: selectedSubgroupRowIndexArray.includes(index),
        texts: row.texts,
        tag: row.tag,
        id: row.id,
        position: row.position,
        rspData: row.rspData,
        isOpen: row.isOpen,
      })),
    };

    const subGroupPayload: Variable = {
      ...subGroup[0],
      rows: subGroupRowPayload.rows.map((row, index) => ({
        ...subGroupRowPayload.rows[index],
        code: row.code,
        isDisabled: row.isDisabled,
      })),
    };

    const response = await createWeightTemplate(subGroup, targets, [
      subGroupPayload,
    ]);

    const subGroupVariable = findSubgroupFromVariables(
      variables,
      response?.subGroup?.categories
    );
    setSubGroup(subGroupVariable);
  };

  const handleMergeSubgroups = async () => {
    const selectedSubgroupNames = Object.keys(selectedSubgroups).filter(
      key => selectedSubgroups[key]
    );

    if (selectedSubgroupNames.length < 2) {
      dispatch({
        type: 'SHOW_WARNING_NOTIFICATION',
        payload: { msg: 'Select at least two subgroups to merge' },
      });
      return;
    }

    if (
      !templateData?.subGroup?.categories ||
      templateData.subGroup.categories.length === 0
    ) {
      dispatch({
        type: 'SHOW_WARNING_NOTIFICATION',
        payload: { msg: 'No subgroups available to merge' },
      });
      return;
    }

    const selectedSubgroupRowIndexArray = selectedSubgroupNames.map(name =>
      findSubgroupRowIndex(subGroup[0].rows, name)
    );
    const selectedSubgroupRowCodes = selectedSubgroupRowIndexArray.map(
      index => subGroup[0].rows[index].code
    );
    const subGroupRowsToSkip = selectedSubgroupRowCodes.filter(
      code => code !== selectedSubgroupRowCodes[0]
    );
    const mergedRowCode = selectedSubgroupRowCodes.join(';');

    const newSubGroupRowPayload = subGroup[0].rows
      .map(row => {
        if (!subGroupRowsToSkip.includes(row.code)) {
          return {
            ...row,
            code: selectedSubgroupRowCodes.includes(row.code)
              ? mergedRowCode
              : row.code,
          };
        }
      })
      .filter(Boolean);

    const subGroupPayload: Variable = {
      ...subGroup[0],
      rows: [...newSubGroupRowPayload] as Row[],
    };

    const response = await createWeightTemplate(subGroup, targets, [
      subGroupPayload,
    ]);

    const subGroupVariable = findSubgroupFromVariables(
      variables,
      response?.subGroup?.categories
    );
    setSubGroup(subGroupVariable);
  };

  const handleUnMergeAllSubgroups = async () => {
    if (!hasMergedSubgroups) {
      dispatch({
        type: 'SHOW_WARNING_NOTIFICATION',
        payload: { msg: 'No original data to unmerge.' },
      });
      return;
    }

    const rowCodesToUnmergeArray = [];
    for (const row of subGroup[0].rows) {
      if (row.code.toString().split(';').length > 1) {
        rowCodesToUnmergeArray.push(...row.code.toString().split(';'));
      }
    }

    const otherRowCodesArray = subGroup[0].rows
      .map(row => {
        if (row.code.toString().split(';').length === 1) {
          return row.code.toString();
        }
      })
      .filter(Boolean);

    const combinedRowCodes = [...otherRowCodesArray, ...rowCodesToUnmergeArray];
    const currentSubGroupVariable = findSubgroupFromInitialVariables(
      variables,
      subGroup
    );
    const rows = currentSubGroupVariable?.rows.filter(row =>
      combinedRowCodes.includes(row.code.toString())
    );

    const subGroupPayload: Variable = {
      ...subGroup[0],
      rows: rows as Row[],
    };

    const response = await createWeightTemplate(subGroup, targets, [
      subGroupPayload,
    ]);
    const subGroupVariable = findSubgroupFromVariables(
      variables,
      response?.subGroup?.categories
    );
    setSubGroup(subGroupVariable);
  };

  const handleInsertNoAnswerRow = async (targetId: string) => {
    const currentTarget = targets.find(
      t => transformVariableId(t.qno) === targetId
    );
    if (!currentTarget) return;

    if (currentTarget.rows.some(r => r.code.toString() === '-')) return;

    const updatedRows: Row[] = [
      { code: '-', texts: { '': 'No answer' }, id: '' } as unknown as Row,
      ...currentTarget.rows,
    ];

    const updatedTargets = targets.map(t => {
      if (transformVariableId(t.qno) === targetId) {
        return { ...t, rows: updatedRows };
      }
      return t;
    });

    await createWeightTemplate(subGroup, updatedTargets);
  };

  const handleRemoveNoAnswerRow = async (targetId: string) => {
    const currentTarget = targets.find(
      t => transformVariableId(t.qno) === targetId
    );
    if (!currentTarget) return;

    const updatedRows: Row[] = currentTarget.rows.filter(
      row => row.code.toString() !== '-'
    );

    const updatedTargets = targets.map(t => {
      if (transformVariableId(t.qno) === targetId) {
        return { ...t, rows: updatedRows };
      }
      return t;
    });

    await createWeightTemplate(subGroup, updatedTargets);
  };

  const handleResetTargetToDefault = async (targetId: string) => {
    const currentTarget = variables.find(
      t => transformVariableId(t.qno) === targetId
    );

    if (!currentTarget) return;

    const updatedRows = currentTarget.rows.map(row => ({
      ...row,
    }));

    await createWeightTemplate(
      subGroup,
      targets,
      undefined,
      updatedRows,
      targetId
    );
  };

  const handleDisableTargets = async (targetId: string) => {
    const targetRowsToDisable = selectedTargets[targetId];
    const rowCodesToDisable = Object.keys(targetRowsToDisable).map(
      key => key.split('=')[1]
    );
    const currentTarget = targets.find(
      t => transformVariableId(t.qno) === targetId
    );
    if (!currentTarget) return;

    const updatedRows = currentTarget.rows.map(row => ({
      ...row,
      isDisabled: rowCodesToDisable.includes(row.code.toString()),
    }));

    await createWeightTemplate(
      subGroup,
      targets,
      undefined,
      updatedRows,
      targetId
    );
  };

  const handleMergeTargets = async (targetId: string) => {
    const targetsToMerge = selectedTargets[targetId];
    const targetRowCodesToMerge = Object.keys(targetsToMerge).map(
      key => key.split('=')[1]
    );
    const mergedRowCode = targetRowCodesToMerge.join(';');
    const mergedRowCodePayload = { code: mergedRowCode } as unknown as Row;

    const newTargetRowsPayload = targets
      .map(target => {
        if (transformVariableId(target.qno) === targetId) {
          return {
            ...target,
            rows: [
              mergedRowCodePayload,
              ...target.rows
                .map(row => {
                  if (targetRowCodesToMerge.includes(row.code.toString())) {
                    return;
                  }
                  return { code: row.code };
                })
                .filter(Boolean),
            ],
          };
        }
      })
      .filter(Boolean)[0]?.rows as unknown as Row[];

    await createWeightTemplate(
      subGroup,
      targets,
      undefined,
      newTargetRowsPayload,
      targetId
    );
  };

  return (
    <div>
      <div className='d-flex align-items-center bg-light w-100 p-2'>
        <div className='d-flex align-items-center'>
          {!!subGroup.length && (
            <span className='font-weight-bold mr-4'>
              {transformVariableId(subGroup[0]?.qno)}
            </span>
          )}
          <DropDownList
            data={filteredSubgroupData}
            textField='qno'
            dataItemKey='id'
            filterable={true}
            onFilterChange={handleSubgroupFilterChange}
            onChange={handleSubgroupChange}
            style={{ width: subGroup.length ? 'fit-content' : '250px' }}
            size='small'
            value={null}
            valueRender={() =>
              subGroup.length ? 'Change subgroup' : '+ Add subgroup'
            }
            className='align-items-center'
            fillMode='solid'
          />

          {!!subGroup.length && (
            <Button
              size='small'
              className='ml-4 text-danger'
              type='button'
              onClick={removeSubGroup}
            >
              - Remove SubGroup
            </Button>
          )}
        </div>

        {!!subGroup.length && (
          <div className='ml-auto d-flex align-item-right'>
            <span className='d-flex'>
              <Button
                size='small'
                className='btn btn-outline-dark ml-2'
                type='button'
                onClick={handleEnableAllSubgroups}
                title='Enable all subgroups'
                disabled={!hasDisabledSubgroups}
              >
                <i className='fas fa-check-double' />
              </Button>
              <Button
                size='small'
                className='btn btn-outline-dark ml-2'
                type='button'
                onClick={handleDisableSubgroups}
                title='Disable selected subgroups'
                disabled={
                  Object.keys(selectedSubgroups).filter(
                    key => selectedSubgroups[key]
                  ).length === 0
                }
              >
                <i className='fas fa-ban' />
              </Button>
              <Button
                size='small'
                className='btn btn-outline-dark ml-2'
                type='button'
                onClick={handleMergeSubgroups}
                title='Merge selected subgroups'
                disabled={
                  Object.keys(selectedSubgroups).filter(
                    key => selectedSubgroups[key]
                  ).length < 2 || hasMergedSubgroups
                }
              >
                <i className='fas fa-layer-plus' />
              </Button>
              <Button
                size='small'
                className='btn btn-outline-dark ml-2'
                type='button'
                onClick={handleUnMergeAllSubgroups}
                title='Unmerge all subgroups'
                disabled={!hasMergedSubgroups}
              >
                <i className='fas fa-layer-minus' />
              </Button>
            </span>
          </div>
        )}
      </div>

      {templateData && (
        <AdvancedWeightingTable
          ref={tableRef}
          isEditable={true}
          data={templateData}
          setMainTemplateData={setMainTemplateData}
          validateTemplateData={validateTemplateData}
          isValidating={isValidating}
          onRemoveTarget={removeTarget}
          selectedSubgroups={selectedSubgroups}
          setSelectedSubgroups={setSelectedSubgroups}
          setShouldValidate={setShouldValidate}
          selectedTargets={selectedTargets}
          setSelectedTargets={setSelectedTargets}
          handleMergeTargets={handleMergeTargets}
          handleResetTargetToDefault={handleResetTargetToDefault}
          handleInsertNoAnswerRow={handleInsertNoAnswerRow}
          handleRemoveNoAnswerRow={handleRemoveNoAnswerRow}
          handleDisableTargets={handleDisableTargets}
        />
      )}

      <div className='d-flex flex-column mt-4'>
        <div className='bg-custom-questions-grey p-2'>
          <div className='d-flex align-items-center'>
            <DropDownList
              data={filteredTargetData}
              textField='qno'
              dataItemKey='id'
              filterable={true}
              onFilterChange={handleTargetFilterChange}
              onChange={handleTargetChange}
              style={{ width: '250px' }}
              size='small'
              value={null}
              valueRender={() => '+ Add target'}
              className='align-items-center'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default StepTwo;
