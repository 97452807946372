export const editCustomRowFromStructure = (state: TODO, payload: { data: TODO, rowId: string }) => {
  const updatedState = { ...state };
  const { data, rowId } = payload;
  const matchedQuestion = updatedState.theData.find((question: { id: string }) => question.id === data.id);

  if (matchedQuestion) {
    const matchedRowFromPayload = data.rows.find((row: { id: string }) => row.id === rowId);
    const matchedRowFromStructure = matchedQuestion.groups.find((row: { id: string }) => row.id === rowId);
    if (matchedRowFromStructure && matchedRowFromPayload) {
      matchedRowFromStructure.customExpr = matchedRowFromPayload.customExpr;
    }
  }

  return updatedState;
}