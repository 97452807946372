export const editCustomRowFromColumn = (state: TODO, payload: { data: TODO, rowId: string, questionInfo: { source: string, id: string } }) => {
  const updatedState = { ...state };
  const { data, rowId } = payload;
  const { source, id } = payload.questionInfo;
  const matchedQuestion = updatedState.state[source].find((question: { id: string }) => question.id === id);

  if (matchedQuestion) {
    const matchedRowFromPayload = data.rows.find((row: { id: string }) => row.id === rowId);
    const matchedRowFromColumn = matchedQuestion.groups.find((row: { id: string }) => row.id === rowId);
    if (matchedRowFromColumn && matchedRowFromPayload) {
      console.log(matchedRowFromPayload, matchedRowFromColumn);
      matchedRowFromColumn.customExpr = matchedRowFromPayload.customExpr;
    }
  }

  updatedState.state.updateTable = true;

  return updatedState;
}