import type React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmActionModal from '../../../../../../../shared/ConfirmActionModal/ConfirmActionModal';
import {
  fetchGetJson,
  fetchDelete,
} from '../../../../../../../../services/services';

import {
  DropDownButton,
  type DropDownButtonItemClickEvent,
} from '@progress/kendo-react-buttons';
import {
  Grid,
  GridColumn as Column,
  type GridCellProps,
} from '@progress/kendo-react-grid';
import { InProgressOverlay } from '../../../../../../../shared/InProgressOverlay/InProgressOverlay';
import { TemplateData, Rounding, CapWeights, MinMaxCap } from './types/types';

interface AdvancedWeightItem {
  id: string;
  text: string;
  pop: number;
  definition: string;
  isAdvancedWeighting: boolean;
  advancedWeightId: string;
  selected?: boolean;
}

interface AlertProps {
  type: 'SUCCESS' | 'INFO' | 'ERROR';
  message: string | string[];
  autoDismiss?: number;
}

interface ExistingWeightsProps {
  token: string;
  datasetId: string;
  setCurrentStep: (step: number) => void;
  setMainTemplateData: (data: TemplateData | null) => void;
  setWeightName: (name: string) => void;
  setWeightDescription: (description: string) => void;
  setRoundingValues: (values: Rounding) => void;
  setCapWeights: (caps: CapWeights) => void;
  setRimGroupCapWeights: (caps: MinMaxCap) => void;
  setMaxIterations: (iterations: number | null) => void;
  setAlert: (alert: AlertProps | null) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  setUniverseDescription: (description: string) => void;
  setUniverseDefinition: (definition: {
    combineData: TODO[];
    expression: string;
  }) => void;
  setCreateUniverse: (createUniverse: boolean) => void;
  setTabSelected?: (tabSelected: number) => void;
  setAdvancedWeightId: (advancedWeightId?: string) => void;
}

const ExistingAdvancedWeights: React.FC<ExistingWeightsProps> = ({
  token,
  datasetId,
  setCurrentStep,
  setMainTemplateData,
  setWeightName,
  setWeightDescription,
  setRoundingValues,
  setCapWeights,
  setRimGroupCapWeights,
  setMaxIterations,
  setAlert,
  isLoading,
  setIsLoading,
  setUniverseDescription,
  setUniverseDefinition,
  setCreateUniverse,
  setTabSelected,
  setAdvancedWeightId,
}) => {
  const history = useHistory();
  const projectId = history.location.pathname.split('/')[2];

  const [advancedWeightsList, setAdvancedWeightsList] = useState<
    AdvancedWeightItem[] | null
  >(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedWeightId, setSelectedWeightId] = useState<string | null>(null);

  const actionItems = [
    { text: 'Edit', icon: 'fa fas fa-edit', id: 'edit' },
    { text: 'Delete', icon: 'fa fas fa-trash-alt', id: 'delete' },
  ];

  useEffect(() => {
    setTabSelected?.(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchWeights = async () => {
      setIsLoading(true);
      try {
        const response: AdvancedWeightItem[] = await fetchGetJson(
          `an/projects/${projectId}/analysis/${datasetId}/weights`,
          token
        );
        const advancedWeights = response.filter(
          weight => weight.isAdvancedWeighting
        );
        setAdvancedWeightsList(advancedWeights);
      } catch (error) {
        console.error('Error fetching weights:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWeights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetId, token]);

  const selectAction = (
    e: DropDownButtonItemClickEvent,
    dataItem: AdvancedWeightItem
  ) => {
    if (e.item.id === 'delete') {
      setSelectedWeightId(dataItem.id);
      setShowDeleteModal(true);
    } else if (e.item.id === 'edit') {
      fetchWeightDefinition(dataItem.advancedWeightId);
    }
  };

  const fetchWeightDefinition = async (advancedWeightId: string) => {
    setIsLoading(true);
    try {
      const response: TemplateData = await fetchGetJson(
        `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced/definitions/${advancedWeightId}`,
        token
      );

      // Update the state in AdvancedWeightingWizard.tsx
      setMainTemplateData(response);
      setWeightName(response.id);
      setWeightDescription(response.description || '');
      setRoundingValues(
        response.settings?.rounding || {
          enabled: false,
          decimals: 0,
          roundingDirection: 0,
          roundingErrorDistribution: 0,
        }
      );
      setAdvancedWeightId(response?.advancedWeightId);
      setCapWeights(extractCapWeights(response));
      setRimGroupCapWeights(extractRimGroupCapWeights(response));
      setMaxIterations(response.settings?.maxIterations || null);
      const universeDefinition = {
        description: response.universe.description,
        expression: response.universe.expression,
      };
      setUniverseDescription(universeDefinition.description || '');
      setUniverseDefinition({
        combineData: [],
        expression: universeDefinition.expression,
      });
      setCreateUniverse(universeDefinition.expression !== '');

      setCurrentStep(1);
    } catch (error) {
      setAlert({
        type: 'ERROR',
        message: 'Error fetching weight definition',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const extractCapWeights = (templateData: TemplateData) => {
    const capWeights: CapWeights = {};
    if (templateData.subGroup?.categories) {
      for (const category of templateData.subGroup.categories) {
        capWeights[category.text] = {
          minCap: category.minCap ?? null,
          maxCap: category.maxCap ?? null,
        };
      }
    }
    return capWeights;
  };

  const extractRimGroupCapWeights = (templateData: TemplateData) => {
    if (!templateData?.rimGroup?.category) {
      return { minCap: null, maxCap: null };
    }
    return {
      minCap: templateData?.rimGroup?.category?.minCap ?? null,
      maxCap: templateData?.rimGroup?.category?.maxCap ?? null,
    };
  };

  const onDeleteAdvancedWeight = async () => {
    if (selectedWeightId) {
      setIsLoading(true);
      try {
        await fetchDelete(
          `an/projects/${projectId}/analysis/${datasetId}/weights/${selectedWeightId}`,
          token
        );
        setShowDeleteModal(false);

        setAdvancedWeightsList(prevWeights =>
          prevWeights
            ? prevWeights.filter(weight => weight.id !== selectedWeightId)
            : null
        );

        setSelectedWeightId(null);
      } catch (error) {
        console.error('Error deleting weight:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className='d-flex flex-column h-100 p-2'>
      {showDeleteModal && (
        <ConfirmActionModal
          projectName={'Advanced Weight'}
          handleClose={() => setShowDeleteModal(false)}
          deleteItem={onDeleteAdvancedWeight}
          type='element'
          errorMessage={null}
          setError={() => {}}
        />
      )}

      {isLoading ? (
        <InProgressOverlay type='fullscreen' theme='primary' />
      ) : advancedWeightsList && advancedWeightsList.length > 0 ? (
        <Grid data={advancedWeightsList} className='weight-grid p-3'>
          <Column field='id' title='Advanced Weight Id' width={200} />
          <Column field='text' title='Text' />
          <Column field='pop' title='Population' width={100} />
          <Column
            width={100}
            cell={(props: GridCellProps) => (
              <td className='d-flex justify-content-end'>
                <DropDownButton
                  iconClass='fa fas fa-ellipsis-h'
                  buttonClass='btn btn-secondary d-flex'
                  items={actionItems}
                  onItemClick={e =>
                    selectAction(e, props.dataItem as AdvancedWeightItem)
                  }
                />
              </td>
            )}
          />
        </Grid>
      ) : (
        <div>No advanced weights found for this project.</div>
      )}
    </div>
  );
};

export default ExistingAdvancedWeights;
