import { KeyboardEvent } from 'react';
import { NumericTextboxWrapper } from './NumericTextboxWrapper';
import { CapWeights, MinMaxCap, TemplateData } from './types/types';

interface CappingTableProps {
  isEditable?: boolean;
  templateData: TemplateData | null;
  capWeights: CapWeights;
  setCapWeights?: (
    value: CapWeights | ((prevCapWeights: CapWeights) => CapWeights)
  ) => void;
  rimGroupCapWeights: MinMaxCap;
  setRimGroupCapWeights?: (value: MinMaxCap) => void;
}

const CappingTable = ({
  isEditable = true,
  templateData,
  rimGroupCapWeights,
  capWeights,
  setCapWeights,
  setRimGroupCapWeights,
}: CappingTableProps) => {
  if (!templateData) {
    return null;
  }

  const categories = templateData?.subGroup?.categories;
  const rimGroup = templateData?.rimGroup?.category;
  const isRimGroup = rimGroup?.targetReferences?.length > 0;

  const handleCapChange = (
    categoryText: string,
    capType: 'minCap' | 'maxCap',
    value: number | null
  ) => {
    setCapWeights?.((prevCapWeights: CapWeights) => ({
      ...prevCapWeights,
      [categoryText]: {
        ...prevCapWeights[categoryText],
        [capType]: value,
      },
    }));
  };

  const preventKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      return;
    }
  };
  return (
    <>
      {!isRimGroup && categories && categories?.length > 0 && (
        <div className='pt-8'>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th />
                  {categories &&
                    categories?.map(category => (
                      <th key={category.text}>{category.text}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Min Cap</td>
                  {categories &&
                    categories?.map(category => (
                      <td key={category.text} className='min-w-100'>
                        {isEditable ? (
                          <NumericTextboxWrapper
                            value={
                              capWeights[category.text]?.minCap != null
                                ? capWeights[category.text].minCap
                                : null
                            }
                            spinners={false}
                            min={0}
                            rounded={null}
                            onChange={e =>
                              handleCapChange(category.text, 'minCap', e.value)
                            }
                            onKeyDown={preventKeyDown}
                          />
                        ) : (
                          <div>{capWeights[category.text]?.minCap}</div>
                        )}
                      </td>
                    ))}
                </tr>
                <tr>
                  <td>Max Cap</td>
                  {categories &&
                    categories?.map(category => (
                      <td key={category.text} className='min-w-100'>
                        {isEditable ? (
                          <NumericTextboxWrapper
                            value={
                              capWeights[category.text]?.maxCap != null
                                ? capWeights[category.text].maxCap
                                : null
                            }
                            spinners={false}
                            min={0}
                            rounded={null}
                            onChange={e =>
                              handleCapChange(category.text, 'maxCap', e.value)
                            }
                          />
                        ) : (
                          <div>{capWeights[category.text]?.maxCap}</div>
                        )}
                      </td>
                    ))}
                </tr>
              </tbody>
            </table>
        </div>
      )}

      {isRimGroup && (
        <div className='d-flex mt-4 align-items-center'>
          <table className='table table-bordered'>
            <tbody>
              <tr>
                <th className='w-50'>Min Cap</th>
                <td className='text-align-left w-50'>
                  {isEditable ? (
                    <NumericTextboxWrapper
                      value={rimGroupCapWeights.minCap}
                      spinners={false}
                      onChange={e =>
                        setRimGroupCapWeights?.({
                          minCap: e.value,
                          maxCap: rimGroupCapWeights.maxCap,
                        })
                      }
                      rounded={null}
                    />
                  ) : (
                    <span>{rimGroupCapWeights.minCap}</span>
                  )}
                </td>
              </tr>
              <tr>
                <th className='w-50'>Max Cap</th>
                <td className='text-align-left w-50'>
                  {isEditable ? (
                    <NumericTextboxWrapper
                      value={rimGroupCapWeights.maxCap}
                      spinners={false}
                      onChange={e =>
                        setRimGroupCapWeights?.({
                          minCap: rimGroupCapWeights.minCap,
                          maxCap: e.value,
                        })
                      }
                      rounded={null}
                    />
                  ) : (
                    <span>{rimGroupCapWeights.maxCap}</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CappingTable;
