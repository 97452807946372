import React from 'react';
import {
  NumericTextBox,
  type NumericTextBoxProps,
  type NumericTextBoxHandle,
} from '@progress/kendo-react-inputs';

/**
 * Wraps a Kendo NumericTextBox and stops mouse-wheel from altering its value.
 * Usage: <NumericTextboxWrapper {...props} />
 */
export const NumericTextboxWrapper = React.forwardRef<
  NumericTextBoxHandle | null,
  NumericTextBoxProps
>((props, forwardedRef) => {
  const localRef = React.useRef<NumericTextBoxHandle | null>(null);

  React.useEffect(() => {
    if (!localRef.current?.element) return;

    const element = localRef.current.element;
    const handleWheel = (evt: WheelEvent) => {
      evt.stopPropagation();
    };

    element.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      element.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <NumericTextBox
      {...props}
      ref={numericRef => {
        localRef.current = numericRef;
        if (typeof forwardedRef === 'function') {
          forwardedRef(numericRef);
        } else if (forwardedRef) {
          (
            forwardedRef as React.MutableRefObject<NumericTextBoxHandle | null>
          ).current = numericRef;
        }
      }}
    />
  );
});
