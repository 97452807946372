import { FullHeightSpinner } from '../../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import AdvancedWeightingTable from './AdvancedWeightingTable';
import { mapRoundDirection } from './utils/utils';
import { TemplateData, Rounding, MinMaxCap, CapWeights } from './types/types';
import CappingTable from './CappingTable';

interface StepFiveProps {
  templateData: TemplateData | null;
  roundingValues: Rounding;
  isLoading: boolean;
  weightName: string;
  weightDescription: string;
  maxIterations?: number | null;
  rimGroupCapWeights: MinMaxCap;
  capWeights: CapWeights;
}

const StepFive = ({
  templateData,
  roundingValues,
  weightName,
  weightDescription,
  isLoading,
  maxIterations,
  rimGroupCapWeights,
  capWeights,
}: StepFiveProps) => {
  if (!templateData) {
    return null;
  }

  const capping =
    rimGroupCapWeights.minCap ||
    rimGroupCapWeights.maxCap ||
    Object.keys(capWeights).length;

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <FullHeightSpinner />
      </div>
    );
  }

  return (
    <>
      <div>
        <AdvancedWeightingTable isEditable={false} data={templateData} />
      </div>

      <div className='mb-4'>
        <div className='font-weight-bold'>Caping</div>
        {capping ? (
          <CappingTable
            isEditable={false}
            templateData={templateData}
            rimGroupCapWeights={rimGroupCapWeights}
            capWeights={capWeights}
          />
        ) : (
          <div className='font-italic small text-gray'>Off</div>
        )}
      </div>

      <div className='d-flex flex-column'>
        <table>
          <thead className='text-align-left'>
            <tr className='font-weight-bold'>
              <th colSpan={2}>Rounding</th>
            </tr>
          </thead>

          {roundingValues.enabled ? (
            <tbody>
              <tr className='text-left'>
                <td className='text-left w-50'>Decimal place for rounding</td>
                <td className='text-left'>{roundingValues.decimals}</td>
              </tr>
              <tr>
                <td className='text-left w-50'>Rounding direction</td>
                <td className='text-left'>
                  {mapRoundDirection(roundingValues.roundingDirection)}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className='font-italic small text-gray'>off</tbody>
          )}
        </table>

        <table className='mt-4'>
          <thead className='text-align-left'>
            <tr className='font-weight-bold'>
              <th colSpan={2}>Number of Iterations</th>
            </tr>
          </thead>
          <tbody>
            <tr className='text-left'>
              <td className='text-left w-50'>
                The total number of Iterations that should run
              </td>
              <td className='text-left'>{maxIterations}</td>
            </tr>
          </tbody>
        </table>

        <table className='mt-4'>
          <thead className='text-align-left'>
            <tr className='font-weight-bold'>
              <th colSpan={2}>Weighting Scheme name</th>
            </tr>
          </thead>
          <tbody>
            <tr className='text-left'>
              <td className='text-left w-50'>Name</td>
              <td className='text-left'>{weightName}</td>
            </tr>
            <tr>
              <td className='text-left w-50'>Description</td>
              <td className='text-left'>{weightDescription || '-'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StepFive;
