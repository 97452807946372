import AdvancedWeightingTable from './AdvancedWeightingTable';
import CappingTable from './CappingTable';
import { NumericTextboxWrapper } from './NumericTextboxWrapper';
import { CapWeights, MinMaxCap, TemplateData } from './types/types';

interface StepThreeProps {
  templateData: TemplateData | null;
  capWeights: {
    [categoryText: string]: { minCap: number | null; maxCap: number | null };
  };
  setCapWeights: (
    value:
      | CapWeights
      | ((prevCapWeights: CapWeights) => CapWeights)
  ) => void;
  maxIterations?: number | null;
  setMaxIterations: (value: number | null) => void;
  rimGroupCapWeights: MinMaxCap;
  setRimGroupCapWeights: (value: MinMaxCap) => void;
}

const StepThree = ({
  templateData,
  capWeights,
  setCapWeights,
  maxIterations,
  setMaxIterations,
  rimGroupCapWeights,
  setRimGroupCapWeights,
}: StepThreeProps) => {
  if (!templateData) {
    return <div>No data available.</div>;
  }

  const rimGroup = templateData?.rimGroup?.category;
  const categories = templateData?.subGroup?.categories;

  return (
    <>
      <AdvancedWeightingTable data={templateData} isEditable={false} />

      {(!!rimGroup?.targetReferences?.length || !!categories?.length) && (
        <p className='font-weight-bold'>Cap Weights</p>
      )}

      <CappingTable
        templateData={templateData}
        rimGroupCapWeights={rimGroupCapWeights}
        capWeights={capWeights}
        setCapWeights={setCapWeights}
        setRimGroupCapWeights={setRimGroupCapWeights}
        isEditable
      />

      <div className='d-flex mt-4 align-items-center'>
        <div className='font-weight-bold mr-4'>Max Iterations</div>
        <NumericTextboxWrapper
          value={maxIterations}
          spinners={false}
          onChange={e => setMaxIterations(e.value)}
          rounded={null}
          className='w-25'
        />
      </div>
    </>
  );
};

export default StepThree;
